@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.burgerholder {
  display: flex;
}

.billjackson {
  font-size: 2rem;
  text-transform: uppercase;
  padding: 2rem 0;
  font-weight: bold;
  -webkit-letter-spacing: 0.5rem;
  -moz-letter-spacing: 0.5rem;
  -ms-letter-spacing: 0.5rem;
  letter-spacing: 0.5rem;
  color: #0D0C1D;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-transition: color 0.3s linear;
  transition: color 0.3s linear;
}

.trickybutton {
  background: none;
  border: none;
  padding: 0px;
  cursor: pointer;
}

.workresult {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(4);
}

.rhythmDisplay {
  overflow: auto;
}

.topbar {
  padding: 15px;
  box-shadow: 0 4px 2px -2px gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App {
  position: relative;
  min-height: 100vh;
}

.mainContent {
  padding-bottom: 2.5rem;
}

.foot {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
}

.topops {
  display: flex;
  align-items: center;
  gap: 1em;
}

.topbuts > button {
  font-size: 18px;
  background: #F89522;
  color: #FFF;
  cursor: pointer;
  padding: 10px 35px;
  margin: 3px;
  border: 2px solid transparent;
  border-radius: 3px;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}

.topbuts > button:hover {
  opacity: 0.8;
}

.topbuts {
  display: flex;
  gap: 1em;
}

.logtit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.laber {
  font-weight: bold;
  font-family: lato;
}

#pointer {
  cursor: pointer;
}

.infobtn {
  display: flex;
  align-items: center;
  width: 25px;
  border-radius: 1000px;
  cursor: pointer;
}

.buttflex {
  display: flex;
  align-items: center;
}

.steady {
  margin-left: 1em;
  padding: 5px;
  cursor: pointer;
}

.bigSteady {
  margin-left: 2em;
  padding: 15px;
  font-size: 1.5em;
  cursor: pointer;
}

.playbtn {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.infoholder {
  display: flex;
  justify-content: space-between;
}

.topselect {
  padding: 5px;
}

.rmspacer {
  height: 2em;
}

.multchoice {
  font-size: 1.5em;
}

.dictwidg {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
}

.rmdictation {
  max-width: 1400px;
  margin: auto;
  padding-left: 1em;
  padding-right: 1em;
}

.answerchoice {
  display: flex;
  align-items: center;
}

.answerchoices {
  margin-top: 4em;
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  gap: 3em;
}

.answerselected {
  background-color: rgba(149, 157, 165, 0.2);
  border-radius: 1000px;
  font-size: 30px;
  padding: 15px;
  margin-right: 2em;
  cursor: pointer;
}

.answerwrong {
  background-color: rgba(255, 0, 0, 1);
  border-radius: 1000px;
  font-size: 30px;
  padding: 15px;
  margin-right: 2em;
  cursor: pointer;
}

.answerletter {
  font-size: 30px;
  padding: 15px;
  margin-right: 2em;
  cursor: pointer;
  border-radius: 1000px;
}

.answerletter:hover {
  background-color: rgba(149, 157, 165, 0.2);
}

.qtext {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 2em;
}

.musiceditor {
  overflow-x: auto;
}

#directions {
  border: none;
  font-size: 16px;
}

#product {
  font-family: lato;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 20px;
  letter-spacing: 2px;
}

#level {
  font-family: lato;
  font-size: 16px;
}

#qtype {
  font-family: lato;
  font-size: 16px;
}

* {
    margin: 0;
    padding: 0;
    font-family: Lato;
}

html {
  height: 100%;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7AC142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7AC142;
  fill: none;
  animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7AC142;
  }
}
